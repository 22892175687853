import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { lazy, Suspense } from "react";
import GlobalStyle from "./globalStyles";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./theme/Theme";
import Loading from "./components/Loading";

//Components
const Home = lazy(() => import("./views/Home/Home"));
const AboutPage = lazy(() => import("./views/About/About"));
const BlogPage = lazy(() => import("./views/Blog/Blog"));
const Projects = lazy(() => import("./views/Projects/Projects"));
const Contacts = lazy(() => import("./views/Contacts/Contacts"));

function App() {
  const location = useLocation();

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={lightTheme}>
        <Suspense fallback={<Loading />}>
          {/* Changed prop from exitBefore to mode */}
          <AnimatePresence mode="wait">
            {/* Changed Switch to Routes */}
            <Routes location={location} key={location.pathname}>
              {/* Changed component to element */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </AnimatePresence>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
